<template>
    <div class="appbox">
      <div class="mybox">
        <span class="tishi">温馨提示：请在此页面等待招聘企业发出的视频面试邀请响应！</span>
        <!-- <el-button class="spbox" type="primary" @click="putvideo">视频面试</el-button> -->
      </div>
        
    </div>
</template>
<script>

export default {
    data(){
        return{
           id:"",
           t:""
        }
    },
    created(){
      this.id=this.$route.query.id
      this.t=this.$route.query.t
    },
    methods:{
    putvideo(){
      this.$router.push({path:"/user/video-call",query:{id:this.id,t:this.t}});
    }
  }
}
</script>
<style scoped>
.appbox{ }
.mybox{ padding-top: 200px;height: 500px; box-sizing: border-box;}
.spbox{ width:300px!important; height: 120px!important; font-size: 18px;}
.tishi{ font-size: 18px;   color:#999; display: inline-block; background: #ffffff; padding:20px 36px; border-radius: 8px; box-shadow: #e9e9e9 1px 2px 8px;}
</style>